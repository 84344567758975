import "./MediaList.css";
import styled from "styled-components";
import { Routes, Route, useParams } from "react-router-dom";
import { HomeLink, SectionHeader } from "./Reusables";

import year2014 from "../data/years/old/2014.json";
import year2015 from "../data/years/old/2015.json";
import year2016 from "../data/years/old/2016.json";
import year2017 from "../data/years/old/2017.json";
import year2018 from "../data/years/old/2018.json";
import year2019 from "../data/years/old/2019.json";
import year2020 from "../data/years/old/2020.json";
import year2021 from "../data/years/old/2021.json";
import year2022 from "../data/years/old/2022.json";
import year2023 from "../data/years/old/2023.json";
import year2024 from "../data/years/old/2024.json";
import year2025 from "../data/years/2025.json";

const allYears = [].concat(
  year2014,
  year2015,
  year2016,
  year2017,
  year2018,
  year2019,
  year2020,
  year2021,
  year2022,
  year2023,
  year2024,
  year2025
);

const MediaUl = styled.ul`
  list-style-type: none;
`;

const DateElem = styled.span`
  font-style: oblique;
`;

const MediaTitle = ({ date, type, title }) => {
  const formattedDate = date.slice(5).replace("-", "/");
  return (
    <li>
      <DateElem>{formattedDate}</DateElem> -{" "}
      <span className={type}>{title}</span>
    </li>
  );
};

const generateMediaList = (titles) => {
  return titles.map((thing) => (
    <MediaTitle key={thing.date + thing.title} {...thing} />
  ));
};

const FormattedMediaPage = () => {
  const { year } = useParams();
  const titles = allYears.filter((title) => title.date.includes(year));

  return (
    <>
      <SectionHeader>stuff, {year}</SectionHeader>
      <MediaUl>
        {titles.length > 0 ? generateMediaList(titles) : "No data found :("}
      </MediaUl>
    </>
  );
};

const MediaList = () => {
  return (
    <>
      <Routes>
        <Route path=":year" element={<FormattedMediaPage />} />
      </Routes>

      <HomeLink to="/">home</HomeLink>
    </>
  );
};

export default MediaList;
